<template>
  <v-container id="availiability-page" fluid>
    <v-row class="fill-height">
      <v-col>
        <v-sheet>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6">
              <v-toolbar class="toolbar-flex" flat>
                <v-btn class="mr-4" color="grey darken-2" outlined @click="setToday">
                  {{ $t('buttons.today') }}
                </v-btn>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey darken-2" outlined v-bind="attrs" v-on="on">
                      <span>{{ $t(typeToLabel[type]) }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = changeTypeOfCalendar('day')">
                      <v-list-item-title> {{ $t('filters.day') }} </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = changeTypeOfCalendar('week')">
                      <v-list-item-title> {{ $t('filters.week') }} </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item @click="type = changeTypeOfCalendar('month')">
                      <v-list-item-title> {{ $t('filters.month') }} </v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
                <div class="toolbar-flex-div"></div>
                <v-btn color="grey darken-2" fab small text @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn color="grey darken-2" fab small text @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ formatTitle($refs.calendar.title) }}
                </v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-autocomplete
                v-model="driver_ids"
                class="ml-5"
                multiple
                :items="driversWithSelectAll"
                clearable
                item-text="full_name"
                item-value="id"
                :label="$t('filters.filterByDriver')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()">
                <!-- Custom selection slot to show a summary of selected items -->
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{
                      driver_ids.length > 1
                        ? `${driver_ids.filter((driver) => driver !== 'all').length + ' ' + $t('select.selected')}`
                        : item.full_name
                    }}
                  </span>
                </template>

                <!-- Custom item slot to style the "Select All" option differently -->
                <template v-slot:item="{ item, on, attrs }">
                  <!-- Check if item is "Select All" and apply custom styling -->
                  <v-list-item v-bind="attrs" v-on="on" class="py-1" :class="{ 'select-all-item': item.id === 'all' }">
                    <!-- Only show checkbox for non-"Select All" items -->
                    <v-checkbox
                      v-if="item.id !== 'all'"
                      class="ma-0 pa-0 mr-5 autocomplete-checkbox"
                      v-model="driver_ids"
                      :value="item.id"></v-checkbox>
                    <v-list-item-title>
                      {{ item.full_name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="6">
              <v-autocomplete
                v-model="vehicle_ids"
                class="ml-5"
                multiple
                :items="vehiclesWithSelectAll"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('filters.filterByVehicle')"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()">
                <!-- Custom selection slot to show a summary of selected items -->
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">
                    {{
                      vehicle_ids.length > 1
                        ? `${vehicle_ids.filter((vehicle) => vehicle !== 'all').length + ' ' + $t('select.selected')}`
                        : item.name
                    }}
                  </span>
                </template>

                <!-- Custom item slot to style the "Select All" option differently -->
                <template v-slot:item="{ item, on, attrs }">
                  <!-- Check if item is "Select All" and apply custom styling -->
                  <v-list-item v-bind="attrs" v-on="on" class="py-1" :class="{ 'select-all-item': item.id === 'all' }">
                    <!-- Only show checkbox for non-"Select All" items -->
                    <v-checkbox
                      v-if="item.id !== 'all'"
                      class="ma-0 pa-0 mr-5 autocomplete-checkbox"
                      v-model="vehicle_ids"
                      :value="item.id"></v-checkbox>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="focus"
            :events="events"
            :interval-format="intervalFormat"
            :type="type"
            color="primary"
            interval-count="48"
            interval-height="18"
            interval-minutes="30"
            @change="updateRange"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            :weekdays="weekDays"
            :weekday-format="getDay"
            :month-format="getMonth"
            style="height: 600px">
            <template v-slot:event="{ event }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    :style="{ backgroundColor: event.color, color: getTextColor(event.color) }">
                    <div class="event">
                      <p class="px-1" v-if="event.type === 'driving_by_driver'">
                        {{ event?.user?.first_name }}
                        <br />
                        {{ event?.user?.last_name }}
                        <br />
                        {{ event.number }}
                        <br />
                        {{ event?.vehicle?.plate_number }}
                        <br />
                        {{ event?.vehicle?.brand }}
                      </p>
                      <p class="px-1" v-if="event.type === 'driving_by_vehicle'">
                        {{ event?.vehicle?.plate_number }}
                        <br />
                        {{ event?.vehicle?.brand }}
                        <br />
                        {{ event.number }}
                        <br />
                        {{ event?.user?.first_name }}
                        <br />
                        {{ event?.user?.last_name }}
                      </p>
                    </div>
                  </div>
                </template>

                <driving-info-tooltip :item="event" />
              </v-tooltip>
            </template>
          </v-calendar>
          <div class="d-flex justify-center flex-wrap mt-10">
            <div class="d-flex align-items-center mx-3" v-for="(legend, index) in legendByEvents" :key="index">
              <v-icon :color="legend.color" class="me-2 status-circle">mdi-circle</v-icon>
              {{ legend.legendText }}
            </div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { formatDateTime } from '@/utils/formatDate';
import DrivingInfoTooltip from '@/components/drivings/DrivingInfoTooltip.vue';
import router from '@/router';

export default {
  name: 'Availability',
  components: { DrivingInfoTooltip },
  props: [],
  data: () => ({
    focus: '',
    type: 'week',
    typeToLabel: {
      // month: 'filters.month',
      week: 'filters.week',
      day: 'filters.day',
    },
    min: null,
    max: null,
    user: {},
    drivers: [],
    vehicles: [],
    drivingsByDrivers: [],
    drivingsByVehicles: [],
    events: [],
    vehicle_ids: [],
    driver_ids: [],
    legendByDrivers: [],
    legendByVehicles: [],
    legendByEvents: [],
  }),

  created() {
    this.user = this.$store.getters['auth/user'];

    this.getAllData();
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },

  computed: {
    driversWithSelectAll() {
      return [{ id: 'all', full_name: i18n.t('buttons.selectAll') }, ...this.drivers];
    },

    vehiclesWithSelectAll() {
      return [{ id: 'all', name: i18n.t('buttons.selectAll') }, ...this.vehicles];
    },
  },

  methods: {
    async getAllData() {
      // At first get all data from api for drivers and vehicles
      await this.getAllVehicles();
      await this.getAllDrivers();

      // Then ensure that state is updated with latest data from local storage
      await this.$store.dispatch('availability/loadAvailabilityData');

      // Finally, add drivers and vehicles in select fields
      if (this.$store.getters['availability/getDrivers'].length > 0) {
        this.driver_ids = this.$store.getters['availability/getDrivers'];
      } else {
        this.selectAllDrivers();
      }

      if (this.$store.getters['availability/getVehicles'].length > 0) {
        this.vehicle_ids = this.$store.getters['availability/getVehicles'];
      } else {
        this.vehicle_ids = [];
      }
    },
    async loadAllDrivings() {
      let queryData = {
        from: this.min,
        to: this.max,
        vehicle_ids: this.vehicle_ids.filter((item) => item !== 'all'),
        driver_ids: this.driver_ids.filter((item) => item !== 'all'),
      };

      await this.$store.dispatch('availability/getAllDrivings', queryData).then((res) => {
        this.createLegend(res.drivings_by_user, res.drivings_by_vehicle);

        this.drivingsByDrivers = res.drivings_by_user.map((e) => {
          e.type = 'driving_by_driver';
          const driverLegend = this.legendByDrivers.find((driver) => driver.driver_id === e.user.id);
          e.color = e.user.color ? e.user.color : driverLegend ? driverLegend.color : '';
          e.pickup_time_original = e.pickup_time;
          e.pickup_time = formatDateTime(e.pickup_time);
          e.expected_drop_off_time = formatDateTime(e.expected_drop_off_time);
          e.expected_comeback_time = formatDateTime(e.expected_comeback_time);
          e.created_at = formatDateTime(e.created_at);
          e.driving_number = e.number + ` [${e.public_number}]`;

          return e;
        });

        this.drivingsByVehicles = res.drivings_by_vehicle.map((e) => {
          e.type = 'driving_by_vehicle';
          const vehicleLegend = this.legendByVehicles.find((vehicle) => vehicle.vehicle_id === e.vehicle.id);
          e.color = e.vehicle.color ? e.vehicle.color : vehicleLegend ? vehicleLegend.color : '';
          e.pickup_time_original = e.pickup_time;
          e.pickup_time = formatDateTime(e.pickup_time);
          e.expected_drop_off_time = formatDateTime(e.expected_drop_off_time);
          e.expected_comeback_time = formatDateTime(e.expected_comeback_time);
          e.created_at = formatDateTime(e.created_at);
          e.driving_number = e.number + ` [${e.public_number}]`;

          return e;
        });
      });

      this.handleEvents();
    },

    createLegend(drivingsByDriver, drivingsByVehicle) {
      this.legendByDrivers = [];
      this.legendByVehicles = [];

      const generateShade = (baseColor, total, index) => {
        const shadeFactor = Math.floor((index / total) * 255);
        return `rgb(${Math.max(0, baseColor[0] - shadeFactor)}, ${Math.max(0, baseColor[1] - shadeFactor)}, ${Math.max(
          0,
          baseColor[2] - shadeFactor
        )})`;
      };

      const uniqueDrivers = [];

      drivingsByDriver.forEach((driving) => {
        if (!uniqueDrivers.some((driver) => driver.id === driving.user.id)) {
          uniqueDrivers.push(driving.user);
        }
      });

      uniqueDrivers.forEach((driver, index) => {
        this.legendByDrivers.push({
          driver_id: driver.id,
          legendText: driver.first_name + ' ' + driver.last_name,
          color: driver.color || generateShade([0, 0, 255], uniqueDrivers.length, index),
        });
      });

      const uniqueVehicles = [];

      drivingsByVehicle.forEach((driving) => {
        if (!uniqueVehicles.some((vehicle) => vehicle.id === driving.vehicle.id)) {
          uniqueVehicles.push(driving.vehicle);
        }
      });

      uniqueVehicles.forEach((vehicle, index) => {
        this.legendByVehicles.push({
          vehicle_id: vehicle.id,
          legendText: vehicle.name,
          color: vehicle.color || generateShade([0, 255, 0], uniqueVehicles.length, index),
        });
      });
    },

    handleEvents() {
      if (this.driver_ids.length > 0 && this.vehicle_ids.length > 0) {
        this.events = [...this.drivingsByDrivers, ...this.drivingsByVehicles];
        this.legendByEvents = [...this.legendByDrivers, ...this.legendByVehicles];
      } else if (this.driver_ids.length > 0) {
        this.events = [...this.drivingsByDrivers];
        this.legendByEvents = [...this.legendByDrivers];
      } else if (this.vehicle_ids.length > 0) {
        this.events = [...this.drivingsByVehicles];
        this.legendByEvents = [...this.legendByVehicles];
      } else {
        this.events = [];
        this.legendByEvents = [];
      }
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    setToday() {
      this.focus = new Date();
      this.type = 'day';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showEvent({ nativeEvent, event }) {
      if (event) {
        // Resolve the URL from the router
        const routeData = router.resolve({
          name: this.$store.getters['auth/role'] == 1 ? 'TabularView' : 'CalendarView',
          query: { driving_id: event.id },
        });

        // Open a new tab with the resolved URL
        window.open(routeData.href, '_blank');
      }

      if (nativeEvent) {
        nativeEvent.stopPropagation();
      }
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}`).toISOString();
      const max = new Date(`${end.date}`).toISOString();
      this.min = min;
      this.max = max;
      this.loadAllDrivings();
    },

    intervalFormat(interval) {
      return interval.time;
    },

    changeTypeOfCalendar(type) {
      if (type === 'day' || type === 'week') {
        setTimeout(() => this.$refs.calendar.scrollToTime(475), 100);
      }
      return type;
    },
    getDay(date) {
      const weekdayTranslations = {
        0: 'weekDays.sunday',
        1: 'weekDays.monday',
        2: 'weekDays.tuesday',
        3: 'weekDays.wednesday',
        4: 'weekDays.thursday',
        5: 'weekDays.friday',
        6: 'weekDays.saturday',
      };

      return i18n.t(weekdayTranslations[date.weekday]).slice(0, 3);
    },
    getMonth(date) {
      const monthsTranslations = {
        1: 'months.jan',
        2: 'months.feb',
        3: 'months.mar',
        4: 'months.apr',
        5: 'months.may',
        6: 'months.jun',
        7: 'months.jul',
        8: 'months.aug',
        9: 'months.sep',
        10: 'months.oct',
        11: 'months.nov',
        12: 'months.dec',
      };

      return i18n.t(monthsTranslations[date.month]).slice(0, 3);
    },
    formatTitle(title) {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const monthsTranslations = {
        0: 'months.jan',
        1: 'months.feb',
        2: 'months.mar',
        3: 'months.apr',
        4: 'months.may',
        5: 'months.jun',
        6: 'months.jul',
        7: 'months.aug',
        8: 'months.sep',
        9: 'months.oct',
        10: 'months.nov',
        11: 'months.dec',
      };

      let month = title.slice(0, -5);
      const year = title.slice(-4);

      monthNames.map((item, index) => {
        if (item === month) {
          month = monthsTranslations[index];
        }
      });
      return i18n.t(month) + ' ' + year;
    },

    getTextColor(bgColor) {
      if (!bgColor) return 'white'; // Default to white if no color

      // Extract the RGB values
      const rgb = bgColor.match(/\d+/g);
      if (!rgb) return 'white';

      // Calculate the brightness
      const brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
      return brightness > 128 ? '#000' : '#fff';
    },

    async getAllVehicles() {
      await this.$store.dispatch('vehicles/getAllVehicles').then((res) => {
        this.vehicles = res.data;
      });
    },

    async getAllDrivers() {
      await this.$store.dispatch('users/getAllUsers', { drivers: true }).then((res) => {
        this.drivers = res.data.length
          ? res.data.map((user) => {
              user.full_name = user.profile?.full_name || 'N/A';
              return user;
            })
          : [];
      });

      // this.selectAllDrivers();
    },

    selectAllDrivers() {
      this.driver_ids = this.drivers.map((option) => option.id);
      this.loadAllDrivings();
    },

    selectAllVehicles() {
      this.vehicle_ids = this.vehicles.map((option) => option.id);
      this.loadAllDrivings();
    },
  },
  watch: {
    '$store.state.addedNewDrivingCounter': {
      immediate: false,
      handler() {
        this.loadAllDrivings();
      },
    },

    driver_ids(newArray, oldArray) {
      const allSelectedInNew = newArray.includes('all');
      const allSelectedInOld = oldArray.includes('all');

      // Check if 'all' was added
      if (allSelectedInNew && !allSelectedInOld) {
        // Select all options
        this.driver_ids = this.driversWithSelectAll.map((option) => option.id);
      }

      if (newArray.filter((item) => item !== 'all').length !== oldArray.filter((item) => item !== 'all').length) {
        this.loadAllDrivings();
      }

      this.$store.dispatch('availability/saveDrivers', newArray);
    },

    vehicle_ids(newArray, oldArray) {
      const allSelectedInNew = newArray.includes('all');
      const allSelectedInOld = oldArray.includes('all');

      // Check if 'all' was added
      if (allSelectedInNew && !allSelectedInOld) {
        // Select all options
        this.vehicle_ids = this.vehiclesWithSelectAll.map((option) => option.id);
      }
      // Check if 'all' exists already
      else if (allSelectedInNew && allSelectedInOld) {
        // Deselect 'all' and retain only selected items
        this.vehicle_ids = newArray.filter((id) => id !== 'all');
      }

      if (newArray.filter((item) => item !== 'all').length !== oldArray.filter((item) => item !== 'all').length) {
        this.loadAllDrivings();
      }

      this.$store.dispatch('availability/saveVehicles', newArray);
    },
  },
};
</script>

<style lang="scss">
#availiability-page {
  .v-event-timed {
    max-width: fit-content;
    border: 0 !important;

    & > div {
      min-height: 100% !important;
      height: fit-content !important;
      background-color: inherit;
      z-index: 2;
    }
  }

  .v-calendar .v-event-timed {
    display: inline-flex;
  }

  @media only screen and (max-width: 480px) {
    .toolbar-flex > .v-toolbar__content {
      flex-wrap: wrap;
    }

    .toolbar-flex-div {
      flex-basis: available;
      margin-right: 100%;
    }
  }

  .event {
    position: relative;
  }

  .event .v-image {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}

// .v-list-item--highlighted {
//   &::before {
//     background-color: #fff;
//     opacity: 0.12 !important;
//   }
// }

// .v-list-item--active {
//   &::before {
//     background-color: var(--primary);
//     opacity: 0.12 !important;
//   }
// }

.select-all-item {
  border-bottom: 1px solid #e0e0e0; /* Add bottom border */
  padding-bottom: 8px;

  &::before {
    background-color: #fff;
  }

  .v-list-item__title {
    color: rgba(0, 0, 0, 0.87);
  }
}

.select-all-item .v-input--selection-controls__input {
  display: none; /* Hide checkbox */
}

.autocomplete-checkbox {
  height: 24px;
  width: 24px;
}
</style>
